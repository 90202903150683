import {
  Button,
  Dropdown,
  Flex,
  Input,
  message,
  Modal,
  Space,
  Table,
} from "antd";
import { useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { ExclamationCircleFilled } from "@ant-design/icons";

import {
  useDeleteJobRequestMutation,
  useDeletePreviewMutation,
  useGetAllJobRequestsQuery,
  useGetAllPreviewsQuery,
} from "../../redux/Service/api";

const { confirm } = Modal;

const PreviewRequestsControl = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const [
    deleteJobRequest,
    { isLoading: delIsLoading, isError: delIsError, error: delError },
  ] = useDeletePreviewMutation();

  const {
    data: allJobRequestsData,
    isError: isJobRequestsDataError,
    isLoading: isJobRequestsDataLoading,
    error: usersDatacatError,
  } = useGetAllPreviewsQuery();
  const allJobRequests = allJobRequestsData?.data ?? null;

  const data = allJobRequests?.map((request) => {
    return {
      key: request?.id ?? "",
      name: request?.name ?? "",
      date: request?.date ?? "",
      phone: request?.phone ?? "",
      address: request?.address ?? "",
      from: request?.from ?? "",
      to: request?.to ?? "",
      is_done: request?.is_done ?? "",
      created_at: request?.created_at ?? "",
    };
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      width: 150,
    },
    {
      title: "phone",
      dataIndex: "phone",
      key: "phone",
      ...getColumnSearchProps("phone"),
      width: 120,
    },
    {
      title: "address",
      dataIndex: "address",
      key: "address",
      ...getColumnSearchProps("address"),
      width: 150,
    },
    {
      title: "date",
      dataIndex: "date",
      key: "date",
      ...getColumnSearchProps("date"),
      width: 130,
    },
    {
      title: "from",
      dataIndex: "from",
      key: "from",
      ...getColumnSearchProps("from"),
      width: 100,
    },
    {
      title: "to",
      dataIndex: "to",
      key: "to",
      ...getColumnSearchProps("to"),
      width: 100,
    },
    {
      title: "is done ?",
      dataIndex: "is_done",
      key: "is_done",
      ...getColumnSearchProps("is_done"),
      width: 100,
    },
    {
      title: "created at",
      dataIndex: "created_at",
      key: "created_at",
      ...getColumnSearchProps("created_at"),
      width: 100,
    },
    {
      title: "actions",
      key: "actions",
      render: (jobRequestDataMapper) => {
        return (
          <div className="d-flex gap-2">
            <Button
              type="primary"
              danger
              ghost
              onClick={() =>
                handleDeleteJobRequestClick(jobRequestDataMapper.key)
              }
            >
              Delete
            </Button>
          </div>
        );
      },
      width: 100,
    },
  ];

  const handleDeleteJobRequestClick = async (id) => {
    confirm({
      title: "Do you want to delete this job request?",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button, this job request will be removed",
      async onOk() {
        try {
          const res = await deleteJobRequest(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("job request is deleted successfully");
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {},
    });
  };
  return (
    <>
      <article className="users position-relative" style={{ direction: "ltr" }}>
        {isJobRequestsDataError && (
          <p className="text-danger">Something went error</p>
        )}
        <article className="container users_container">
          <h2 className="main_heading">job Requests</h2>
          <Table
            columns={columns}
            dataSource={data}
            loading={isJobRequestsDataLoading}
            bordered
            rowClassName={"rowName"}
            pagination={{
              showSizeChanger: true,
              pageSizeOptions: [9, 10, 20, 30, 50],
              showTotal: (total) => `العدد الكلي: ${total}`,
            }}
            scroll={{ y: 400, x: 500 }}
          />
        </article>
      </article>
    </>
  );
};
export default PreviewRequestsControl;
