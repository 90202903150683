import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  faSquareFacebook,
  faSquareInstagram,
  faYoutube,
  faSquareXTwitter,
} from "@fortawesome/free-brands-svg-icons";

import { language } from "../../language/Language";

import { Link } from "react-router-dom";

import "./Footer.css";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  const route = useNavigate();

  return (
    <footer className="pt-5 box_shadow new-light-bg-color new-text-color">
      <div className="footer container">
        <div className="row px-4 px-lg-0 mb-2">
          {/* Company Column */}
          <ul className="col-12 col-sm-12 col-lg-5 col-xl-2 text-start-fremwork mb-2 flex-column">
            <li>
              <h6>{language === "ar" ? "شركة" : "Company"}</h6>
            </li>
            <li>
              <Link className="new-text-color" to="/">
                {language === "ar" ? "من نحن" : "About Us"}
              </Link>
            </li>
            <li>
              <Link className="new-text-color" to="/Connect">
                {language === "ar" ? "تواصل معانا" : "Contact Us"}
              </Link>
            </li>
          </ul>

          {/* Important Links Column */}
          <ul className="col-12 col-sm-12 col-lg-5 col-xl-2 text-start-fremwork mb-2 flex-column">
            <li>
              <h6>{language === "ar" ? "روابط مهمة" : "Important Links"}</h6>
            </li>
            <li>
              <Link className="new-text-color" to="/Products">
                {language === "ar" ? "منتجاتنا" : "Our Products"}
              </Link>
            </li>
            <li>
              <Link className="new-text-color" to="/Articles">
                {language === "ar" ? "مقالات" : "Articles"}
              </Link>
            </li>
          </ul>

          {/* Help Column */}
          <ul className="col-12 col-sm-12 col-lg-5 col-xl-3 text-start-fremwork mb-2 flex-column">
            <li>
              <h6>
                {language === "ar" ? "تريد مساعدة؟" : "Do you want help?"}
              </h6>
            </li>
            <li>
              <Link
                to="mailto:edenmareltd@gmail.com"
                className="new-text-color"
              >
                <FontAwesomeIcon icon={faEnvelope} /> edenmareltd@gmail.com
              </Link>
            </li>
            <li>
              <span className="new-text-color">
                <FontAwesomeIcon icon={faPhone} />{" "}
                <span style={{ fontSize: "1.15rem" }} dir="ltr">
                  +201065730685
                </span>
              </span>
            </li>
          </ul>

          {/* Location Column */}
          <ul className="col-12 col-sm-12 col-lg-5 col-xl-4 text-start-fremwork mb-2 flex-column">
            <li>
              <h6>{language === "ar" ? "موقعنا" : "Our Location"}</h6>
            </li>
            <li>
              <Link
                to="https://maps.app.goo.gl/X8zPBVH8xyYRj88p6"
                target="_blank"
                className="gap-2 d-flex flex-nowrap new-text-color"
              >
                <i className="bi bi-geo-alt"></i>
                {language === "ar"
                  ? "كفر طهرمس - فيصل"
                  : "kafrtohormos-faysal - Giza, Egypt"}
              </Link>
              <iframe
                async
                src="https://www.google.com/maps/embed?pb=!1m21!1m12!1m3!1d1221.2795626470481!2d31.164733967656314!3d30.028232848425525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m6!3e6!4m0!4m3!3m2!1d30.028423325251858!2d31.16464293047656!5e0!3m2!1sen!2seg!4v1725808812024!5m2!1sen!2seg"
                height="250"
                style={{ border: "0", width: "100%" }}
                allowFullScreen={true}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </li>
          </ul>
        </div>

        {/* Follow Us Section */}
        <div className="row px-4 px-lg-0 justify-content-around d-flex">
          <div className="col-12 col-lg-4 text-start-fremwork">
            <h6 className="heading_meada new-text-color">
              {language === "ar" ? "تابعنا" : "Follow us"}
            </h6>
            <div className="meada">
              <a
                href="https://www.facebook.com/EdenmareEG"
                target="_blank"
                rel="noopener noreferrer"
                className="new-heading-color"
              >
                <FontAwesomeIcon icon={faSquareFacebook} />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Copyright Section */}
      <p className="m-0 p-3 copay new-text-color">
        {language === "ar"
          ? "Ⓒ جميع الحقوق محفوظة لشركة"
          : "Ⓒ All rights reserved to the company"}
        <a
          href="https://back-end.edenmare-int.com"
          target="_blank"
          rel="noopener noreferrer"
          className="ms-1 me-1 mt-0 mb-0 new-heading-color"
        >
          Eden-mare
        </a>
      </p>
    </footer>
  );
};

export default Footer;
