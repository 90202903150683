import "./preview.css";
import { FieldTimeOutlined } from "@ant-design/icons";
import { language } from "../../language/Language";
import { useState } from "react";
import { Modal, Form, Input, DatePicker, TimePicker, Button } from "antd";
import { useRequestPreviewMutation } from "../../redux/Service/api";
import { notification } from "antd";

function Preview() {
  const [openPreviewRequestModal, setOpenPreviewRequestModal] = useState(false);
  const [applyPreview, { isLoading, isError, error }] =
    useRequestPreviewMutation();

  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    const formattedValues = {
      ...values,
      date: values.date.format("YYYY-MM-DD"),
      from: values.from.format("HH:mm"),
      to: values.to.format("HH:mm"),
    };

    console.log(formattedValues);

    try {
      const response = await applyPreview(formattedValues);

      if (!response.error) {
        setOpenPreviewRequestModal(false);
        form.resetFields();

        notification.success({
          message: "Success",
          description: "Preview request submitted successfully!",
          placement: "center",
        });
      } else {
        throw new Error(response.error?.message || "Submission failed");
      }

      console.log(response);
    } catch (err) {
      console.error("Error submitting the form:", err);

      notification.error({
        message: "Error",
        description:
          err.message || "Something went wrong while submitting the form.",
        placement: "center",
      });
    }
  };

  return (
    <>
      <div className="preview" onClick={() => setOpenPreviewRequestModal(true)}>
        <FieldTimeOutlined className="preview-icon" />
        <span className="preview-link">
          <p>
            <strong>
              {language === "ar" ? "طلب معاينة" : "request a preview"}
            </strong>
          </p>
        </span>
      </div>

      <Modal
        className=""
        style={{ marginTop: "-2rem" }}
        title={language === "ar" ? "طلب معاينة" : "Request a Preview"}
        open={openPreviewRequestModal}
        onCancel={() => setOpenPreviewRequestModal(false)}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            name="name"
            label={language === "ar" ? "الاسم" : "Name"}
            rules={[
              {
                required: true,
                message:
                  language === "ar"
                    ? "الرجاء إدخال الاسم"
                    : "Please enter your name",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="phone"
            label={language === "ar" ? "الهاتف" : "Phone"}
            rules={[
              {
                required: true,
                message:
                  language === "ar"
                    ? "الرجاء إدخال رقم الهاتف"
                    : "Please enter your phone number",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="address"
            label={language === "ar" ? "العنوان" : "Address"}
            rules={[
              {
                required: true,
                message:
                  language === "ar"
                    ? "الرجاء إدخال العنوان"
                    : "Please enter your address",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="date"
            label={language === "ar" ? "التاريخ" : "Date"}
            rules={[
              {
                required: true,
                message:
                  language === "ar"
                    ? "الرجاء اختيار التاريخ"
                    : "Please select a date",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>

          <div className="d-flex justify-content-between">
            <Form.Item
              name="from"
              label={language === "ar" ? "من (الساعة)" : "From (hour)"}
              rules={[
                {
                  required: true,
                  message:
                    language === "ar"
                      ? "الرجاء اختيار الوقت"
                      : "Please select the start time",
                },
              ]}
            >
              <TimePicker format="HH:mm" />
            </Form.Item>

            <Form.Item
              name="to"
              label={language === "ar" ? "إلى (الساعة)" : "To (hour)"}
              rules={[
                {
                  required: true,
                  message:
                    language === "ar"
                      ? "الرجاء اختيار الوقت"
                      : "Please select the end time",
                },
              ]}
            >
              <TimePicker format="HH:mm" />
            </Form.Item>
          </div>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading} block>
              {language === "ar" ? "إرسال" : "Submit"}
            </Button>
            {isError && (
              <p style={{ color: "red" }}>
                {error?.data?.message || "An error occurred"}
              </p>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Preview;
