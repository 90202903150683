import {
  faDownload,
  faSquarePen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, message, Modal } from "antd";
import { useState } from "react";
import "./AddNews.css";
import { ExclamationCircleFilled } from "@ant-design/icons";
import {
  useDeleteNewsMutation,
  useGetAllNewsQuery,
} from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import AddNews from "./AddNews";
import EditNews from "./EditNews";
import Linkify from "react-linkify";
import { language } from "../../language/Language";

const { confirm } = Modal;

function NewsControl() {
  // const navigate = useNavigate();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [articleIdForEdit, setArticleIdForEdit] = useState(null);
  const [rerenderEditKey, setRerenderEditKey] = useState(0);
  const [modal, contextHolder] = Modal.useModal();

  const {
    data: allNewsData,
    isError: isAllNewsError,
    isLoading: isAllNewsLoading,
    error: allNewsError,
  } = useGetAllNewsQuery();

  const allNews = allNewsData?.data ?? null;

  const [
    deleteNews,
    { isLoading: delIsLoading, isError: delIsError, error: delError },
  ] = useDeleteNewsMutation();

  const handleDeleteNews = async (id) => {
    confirm({
      title: "Do you really want to delete this News?",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button, this News will be removed ",
      async onOk() {
        try {
          const res = await deleteNews(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("News is deleted successfully");
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {},
    });
  };

  const CustomLink = (href, text, key) => (
    <a href={href} target="_blank" rel="noopener noreferrer" key={key}>
      {text}
    </a>
  );

  return (
    <div
      className="gap-3 position-relative"
      dir={language === "ar" ? "rtl" : "ltr"}
    >
      <h2 className="main_heading">News</h2>
      {isAllNewsLoading && <Loading />}
      {isAllNewsError && <p className="text-danger">Something went error</p>}
      <div className="d-flex justify-content-center align-items-center mb-5 mt-4">
        <Button type="primary" onClick={() => setOpenAddModal(true)}>
          add News
        </Button>
      </div>
      <section className="light">
        <div className="container py-2">
          {allNews?.map((news) => (
            <article className="postcard light blue" key={news.id}>
              <div className="postcard__img_link">
                <img className="postcard__img" src={news.photo} alt="Title" />
              </div>
              <div className="postcard__text t-dark">
                <h1 className="postcard__title blue">
                  <div>
                    {news.title} <span className="fs-6">{news.author}</span>
                  </div>
                </h1>

                <div className="postcard__bar"></div>
                <div className="postcard__preview-txt">
                  <Linkify componentDecorator={CustomLink}>
                    {news.content}
                  </Linkify>{" "}
                </div>
                <ul className="postcard__tagbox gap-3">
                  <Button
                    danger
                    type="primary"
                    icon={<FontAwesomeIcon size="lg" icon={faTrash} />}
                    onClick={() => {
                      handleDeleteNews(news.id);
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    type="primary"
                    icon={<FontAwesomeIcon size="lg" icon={faSquarePen} />}
                    onClick={() => {
                      setOpenEditModal(true);
                      setArticleIdForEdit(news.id);
                      setRerenderEditKey((prev) => prev + 1);
                    }}
                  >
                    Update
                  </Button>
                </ul>
              </div>
            </article>
          ))}
        </div>
      </section>

      <EditNews
        setOpenEditModal={setOpenEditModal}
        openEditModal={openEditModal}
        articleIdForEdit={articleIdForEdit}
        rerenderEditKey={rerenderEditKey}
      />

      <AddNews setOpenAddModal={setOpenAddModal} openAddModal={openAddModal} />
    </div>
  );
}

export default NewsControl;
