import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Modal, Space, message, Button, Input } from "antd";
import { useAddJobRequestMutation } from "../../redux/Service/api";
import TextArea from "antd/es/input/TextArea";
import { language } from "../../language/Language";

const ApplyJob = ({ setOpenAddModal, openAddModal, jobIdForApply }) => {
  const [modalKey, setModalKey] = useState(0);

  const [name, setName] = useState("");
  const [qualification, setQualification] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [experience, setExperience] = useState("");

  const [applyJob, { isLoading, isError, error }] = useAddJobRequestMutation();

  const handleAddJob = async () => {
    if (!name) {
      message.error(
        language === "ar" ? "يرجى إضافة الاسم" : "Please add the Name"
      );
      return;
    }
    if (!qualification) {
      message.error(
        language === "ar" ? "يرجى إضافة المؤهل" : "Please add the qualification"
      );
      return;
    }
    if (!address) {
      message.error(
        language === "ar" ? "يرجى إضافة العنوان" : "Please add the address"
      );
      return;
    }
    if (!phone) {
      message.error(
        language === "ar" ? "يرجى إضافة الهاتف" : "Please add the phone"
      );
      return;
    }
    if (!experience) {
      message.error(
        language === "ar" ? "يرجى إضافة الخبرة" : "Please add the experience"
      );
      return;
    }

    const requestBody = {
      job_id: jobIdForApply,
      name,
      qualification,
      address,
      phone,
      experience,
    };

    try {
      const res = await applyJob(requestBody);

      setName("");
      setAddress("");
      setQualification("");
      setPhone("");
      setExperience("");

      setModalKey((prev) => prev + 1);
      res.data.message
        ? message.info(res.data.message)
        : message.success(
            language === "ar"
              ? "تمت إضافة طلب الوظيفة بنجاح"
              : "Job request added successfully"
          );
      setOpenAddModal(false);
    } catch (error) {
      console.error("Error adding Job request :", error);
      message.error(
        language === "ar"
          ? "حدث خطأ أثناء إضافة طلب الوظيفة. يرجى المحاولة مرة أخرى."
          : "Error adding Job request. Please try again."
      );
    }
  };

  return (
    <Modal
      key={modalKey}
      title={language === "ar" ? "طلب التوظيف" : "Employment application"}
      open={openAddModal}
      onCancel={() => setOpenAddModal(false)}
      width={600}
      footer
      style={{ direction: "rtl", textAlign: "right" }}
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size="large"
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
          size="large"
        >
          <Row className="g-2">
            <Input
              size="large"
              placeholder={language === "ar" ? "الاسم" : "the name"}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              size="large"
              placeholder={language === "ar" ? "العنوان" : "the address"}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <Input
              size="large"
              placeholder={language === "ar" ? "الهاتف" : "the phone"}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <Input
              size="large"
              placeholder={language === "ar" ? "المؤهلات" : "Qualifications"}
              value={qualification}
              onChange={(e) => setQualification(e.target.value)}
            />
            <TextArea
              placeholder={language === "ar" ? "الخبرة" : "Experience"}
              value={experience}
              onChange={(e) => setExperience(e.target.value)}
            />
          </Row>

          <Row className="g-2">
            <Button type="primary" onClick={handleAddJob} loading={isLoading}>
              {language === "ar" ? "تقديم" : "Presentation"}
            </Button>
          </Row>
        </Space>
      </Space>
    </Modal>
  );
};

export default ApplyJob;
