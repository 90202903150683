import "./Connect.css";
import { language } from "../../language/Language";
function Connect() {
  return (
    <article className="contact my-5 new-bg-color">
      <article className="container contact_container">
        <form action="" className="form_contact">
          <span>
            {language === "ar" ? "اترك رسالتك" : "Leave your message"}
          </span>
          <h2>
            {language === "ar"
              ? "نحن نحب أن نسمع منك"
              : "We would love to hear from you"}
          </h2>
          <input
            id="name"
            type="text"
            placeholder={language === "ar" ? "الاسم" : "Name"}
          />
          <input
            id="email"
            type="text"
            placeholder={language === "ar" ? "البريد الإلكتروني" : "E-mail"}
          />
          <input
            id="subject"
            type="text"
            placeholder={language === "ar" ? "موضوع" : "Theme"}
          />
          <textarea
            id="text"
            name=""
            cols="30"
            rows="7"
            placeholder={
              language === "ar" ? "ادخل رسالتك" : "Enter your message"
            }
          ></textarea>
          <button className="normal but_email btn col-12 ">
            {language === "ar" ? "ارسل" : "Send"}
          </button>
        </form>
      </article>
    </article>
  );
}

export default Connect;
