import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Button, Input, message, Modal, Space, Spin, Upload } from "antd";

import {
  useEditAboutMutation,
  useGetFullAboutDetailsQuery,
} from "../../redux/Service/api";

import TextArea from "antd/es/input/TextArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const EditAbout = ({
  setOpenEditModal,
  openEditModal,
  tipIdForEdit,
  rerenderEditKey,
}) => {
  const [modalKey, setModalKey] = useState(0);
  const [modalKey1, setModalKey1] = useState(10);
  const [modalKey2, setModalKey2] = useState(100);

  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [info, setInfo] = useState("");
  const [about, setAbout] = useState("");
  //
  const [nameEN, setNameEN] = useState("");
  const [positionEN, setPositionEN] = useState("");
  const [infoEN, setInfoEN] = useState("");
  const [aboutEN, setAboutEN] = useState("");

  const [vidoeLink, setVidoeLink] = useState(null);

  const [file, setFile] = useState(null);
  const handleFileChange = (fileList) => {
    console.log("file", fileList);

    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };
  const handleVideoChange = (fileList) => {
    console.log("file", fileList);

    if (fileList.length > 0) {
      setVidoeLink(fileList[0].originFileObj);
    } else {
      setVidoeLink(null);
    }
  };

  const {
    data: allAboutData,
    isError: isAllAboutError,
    isLoading: isAllAboutLoading,
    error: allAboutError,
  } = useGetFullAboutDetailsQuery(tipIdForEdit);

  const allAboutDetails = allAboutData?.data ?? null;

  console.log("allAboutDetails", allAboutDetails);
  console.log("file", file);

  useEffect(() => {
    setName(allAboutDetails?.manager_name);
    setPosition(allAboutDetails?.manager_position);
    setInfo(allAboutDetails?.manager_information);
    setAbout(allAboutDetails?.about_us);
    //
    setNameEN(allAboutDetails?.manager_name_en);
    setPositionEN(allAboutDetails?.manager_position_en);
    setInfoEN(allAboutDetails?.manager_information_en);
    setAboutEN(allAboutDetails?.about_us_en);

    // setVidoeLink(allAboutDetails?.video);

    setVidoeLink([
      {
        uid: "-1",
        name: "Main-video",
        status: "done",
        url: allAboutDetails?.video,
      },
    ]);
    setFile([
      {
        uid: "-1",
        name: "Main-image",
        status: "done",
        url: allAboutDetails?.photo,
      },
    ]);
    setModalKey1((prev) => prev + 11);
    setModalKey2((prev) => prev + 11);
  }, [allAboutDetails, rerenderEditKey]);

  const [editAbout, { isLoading: isEditLoading, isError: isEditError, error }] =
    useEditAboutMutation();

  const handleEditAbout = async () => {
    if (!name) {
      message.error("Please add the name");
      return;
    }
    if (!position) {
      message.error("Please add the position");
      return;
    }
    if (!info) {
      message.error("Please add the more information");
      return;
    }
    if (!about) {
      message.error("Please add the about us");
      return;
    }
    if (!nameEN) {
      message.error("Please add the english name");
      return;
    }
    if (!positionEN) {
      message.error("Please add the english position");
      return;
    }
    if (!infoEN) {
      message.error("Please add the english more information");
      return;
    }
    if (!aboutEN) {
      message.error("Please add the english about us");
      return;
    }
    // if (!vidoeLink) {
    //   message.error("Please add the vidoe Link");
    //   return;
    // }

    // const jobBody = {
    //   manager_name: name,
    //   manager_name_en: nameEN,
    //   manager_position: position,
    //   manager_position_en: positionEN,
    //   manager_information: info,
    //   manager_information_en: infoEN,
    //   about_us: about,
    //   about_us_en: aboutEN,
    // };
    const formData = new FormData();

    formData.append("manager_name", name);
    formData.append("manager_name_en", nameEN);
    formData.append("manager_position", position);
    formData.append("manager_position_en", positionEN);
    formData.append("manager_information", info);
    formData.append("manager_information_en", infoEN);
    formData.append("about_us", about);
    formData.append("about_us_en", aboutEN);

    // formData.append("video_link", vidoeLink);

    if (typeof file === "object" && !Array.isArray(file)) {
      formData.append("photo", file);
    }
    if (typeof vidoeLink === "object" && !Array.isArray(vidoeLink)) {
      formData.append("video", vidoeLink);
    }

    try {
      const res = await editAbout({ id: tipIdForEdit, jobBody: formData });

      setModalKey((prev) => prev + 1);
      setFile(null);
      // setModa2Key((prev) => prev + 1);
      setVidoeLink(null);
      // setAbout("");
      // setAboutEN("");
      // setPosition("");
      // setPositionEN("");
      // setInfo("");
      // setInfoEN("");
      // setName("");
      // setNameEN("");
      // setVidoeLink("");
      res.data.message
        ? message.info(res.data.message)
        : message.success("About edited successfully");
      setOpenEditModal(false);
    } catch (error) {
      console.error("Error edited About:", error);
      message.error("Error edited About. Please try again.");
    }
  };

  return (
    <Modal
      key={modalKey}
      title="Edit"
      centered
      open={openEditModal}
      onCancel={() => setOpenEditModal(false)}
      width={700}
      footer
    >
      {isAllAboutLoading && (
        <p>
          <Spin size="small" style={{ marginRight: "0.2rem" }} />
          loading details...
        </p>
      )}
      {isAllAboutError && <p className="text-danger">Something went error</p>}
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size="large"
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
          size="large"
        >
          <Row className="g-2">
            <Col
              className="gap-3 d-flex flex-column col-6"
              style={{ direction: "rtl", textAlign: "right" }}
            >
              <Input
                placeholder="من هو"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Input
                placeholder="الوظيفة"
                value={position}
                onChange={(e) => setPosition(e.target.value)}
              />

              <TextArea
                placeholder="معلومات اضافية"
                value={info}
                onChange={(e) => setInfo(e.target.value)}
              />

              <TextArea
                placeholder="عن الشركة"
                value={about}
                onChange={(e) => setAbout(e.target.value)}
              />
            </Col>
            <Col
              className="gap-3 d-flex flex-column col-6"
              style={{ direction: "rtl", textAlign: "right" }}
            >
              <Input
                placeholder="Who"
                value={nameEN}
                onChange={(e) => setNameEN(e.target.value)}
              />
              <Input
                placeholder="Position"
                value={positionEN}
                onChange={(e) => setPositionEN(e.target.value)}
              />

              <TextArea
                placeholder="more informations"
                value={infoEN}
                onChange={(e) => setInfoEN(e.target.value)}
              />

              <TextArea
                placeholder="about eden mare"
                value={aboutEN}
                onChange={(e) => setAboutEN(e.target.value)}
              />
            </Col>
          </Row>
          {/* <Row className="g-2">
            <Col className="gap-3 d-flex flex-column col-12">
              <Input
                placeholder="Video link"
                value={vidoeLink}
                onChange={(e) => setVidoeLink(e.target.value)}
              />
            </Col>
          </Row> */}
          <Row className="g-2">
            <Col className="gap-3 d-flex flex-column col-6">
              <Upload
                key={modalKey1}
                customRequest={({ file, onSuccess, onError }) => {
                  try {
                    onSuccess();
                  } catch (error) {
                    console.error("Error handling file:", error);
                  }
                }}
                onChange={({ fileList }) => {
                  handleFileChange(fileList);
                }}
                listType="picture"
                maxCount={1}
                defaultFileList={file}
              >
                <Button
                  loading={false}
                  icon={<FontAwesomeIcon icon={faDownload} />}
                >
                  Upload Image (Max: 1)
                </Button>
              </Upload>
            </Col>
            <Col className="gap-3 d-flex flex-column col-6">
              <Upload
                key={modalKey2}
                customRequest={({ file, onSuccess, onError }) => {
                  try {
                    onSuccess();
                  } catch (error) {
                    console.error("Error handling file:", error);
                  }
                }}
                onChange={({ fileList }) => {
                  handleVideoChange(fileList);
                }}
                listType="picture"
                maxCount={1}
                defaultFileList={vidoeLink}
              >
                <Button
                  loading={false}
                  icon={<FontAwesomeIcon icon={faDownload} />}
                >
                  Upload Video (Max: 1)
                </Button>
              </Upload>
            </Col>
          </Row>
          <Row className="g-2">
            <Button
              type="primary"
              onClick={handleEditAbout}
              loading={isEditLoading}
            >
              Edit
            </Button>
          </Row>
        </Space>
      </Space>
    </Modal>
  );
};

export default EditAbout;
