import "./whatsapp.css";
import { WhatsAppOutlined } from "@ant-design/icons";

import { language } from "../../language/Language";

function Whatsapp() {
  // const language = localStorage.getItem("language") || "ar";

  return (
    // <!-- start whatsapp_massenger  -->
    <div className="whatsapp">
      <a
        href="https://api.whatsapp.com/send?phone=201065730685"
        target="_blank"
        rel="noopener noreferrer"
      >
        <WhatsAppOutlined />
      </a>
      <a
        href="https://api.whatsapp.com/send?phone=201065730685"
        target="_blank"
        rel="noopener noreferrer"
      >
        <p className="whatsapp-text">
          <strong>{language === "ar" ? "تواصل معنا" : "Contact Us"}</strong>
        </p>
      </a>
    </div>
  );
}

export default Whatsapp;
