import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";

// img
// import SomeProducts1 from "../../assets/SomeProducts1.jpg";
// import SomeProducts2 from "../../assets/SomeProducts2.jpg";
// import SomeProducts3 from "../../assets/SomeProducts3.jpg";
// import SomeProducts4 from "../../assets/SomeProducts4.jpg";

import "./SomeProducts.css";
import { Image } from "react-bootstrap";
// import logo from "../../assets/logo.jpg";
import {
  useGetAllCategoriesQuery,
  useGetAllProductsQuery,
} from "../../redux/Service/api";
import { Spin } from "antd";

function SomeProducts() {
  const {
    data: allitemsData,
    isError: isAllitemsError,
    isLoading: isAllitemsLoading,
    error: allitemsError,
  } = useGetAllProductsQuery();

  const allItems = allitemsData?.data ?? null;

  return (
    <div className=" mb-5" data-aos="fade-left">
      <Swiper
        watchSlidesProgress={true}
        slidesPerGroup={1}
        loop={true}
        autoplay={{
          delay: 3000,
          pauseOnMouseEnter: false,
          disableOnInteraction: false,
        }}
        // loopFillGroupWithBlank={true}
        modules={[Autoplay, Pagination]}
        breakpoints={{
          768: {
            slidesPerView: 3,
            spaceBetween: 2,
          },
          992: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
        }}
        slidesPerView={1}
        spaceBetween={5}
        className="mySwiper w-100 "
      >
        {allItems?.map((item) => (
          <SwiperSlide key={item.id}>
            <div
              style={{
                height: "100%",
                width: "100%",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
            >
              <Image
                src={item.photo}
                className="sldier_img"
                style={{ objectFit: "cover", objectPosition: "top" }}
              />
              <h5
                className="text-center fw-light px-2 py-3 px-3 text-align-center"
                style={{ fontSize: "15px" }}
              >
                {item.name}
              </h5>
            </div>
          </SwiperSlide>
        ))}
        {isAllitemsLoading && <Spin />}
        {isAllitemsError && (
          <>
            {/* <SwiperSlide>
              <Image src={SomeProducts2} className="sldier_img" />
            </SwiperSlide> */}
            {/* <SwiperSlide>
              <Image src={SomeProducts4} className="sldier_img" />
            </SwiperSlide>
            <SwiperSlide>
              <Image src={SomeProducts3} className="sldier_img" />
            </SwiperSlide> */}
          </>
        )}
      </Swiper>
    </div>
  );
}

export default SomeProducts;
