import { createBrowserRouter } from "react-router-dom";
import RootLayout from "../components/RootLayout";
import { ErrorPage } from "./ErrorPage";
import Home from "../Pages/Home/Home";
import Jobs from "../Pages/jobs/Jobs";
import Connect from "../Pages/Connect/Connect";
import UsersControl from "../Dashboard/UsersControl/UsersControl";
import Categories from "../Dashboard/Categories/Categories";
import ProductsControl from "../Dashboard/ProductsControl/ProductsControl";

import ArticlesControl from "../Dashboard/ArticlesControl/ArticlesControl";
import AddSlider from "../Dashboard/BannerSlider/AddSlider";
import Login from "../Pages/Login/Login";
import Dashboard from "../Dashboard";
import PhotoSliders from "../Dashboard/SliderPhotos/PhotoSliders";
import NewsControl from "../Dashboard/NewsControl/NewsControl";
import JobsControl from "../Dashboard/JobsControl/JobsControl";
import JobRequestsControl from "../Dashboard/JobRequestsControl/JobRequestsControl";
// import Products from "../Pages/ProductsS/Products";
import ArticleDetails from "../Pages/ArticleDetails/ArticleDetails";
import NewsDetails from "../Pages/NewsDetails/NewsDetails";
import AboutControl from "../Dashboard/AboutControl/AboutControl";
import Articles from "../Sections/Articles/Articles";
import PreviewRequestsControl from "../Dashboard/previewRequestsControl/PreviewRequestsControl";
// import { Product, Products } from "../Pages";
import Products from "../Pages/Products/Products";
import Product from "../Pages/Product/Product";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      { path: "Jobs", element: <Jobs /> },
      { path: "Connect", element: <Connect /> },
      { path: "Articles/:articleId", element: <ArticleDetails /> },
      { path: "News/:newsId", element: <NewsDetails /> },
      // { path: "Products", element: <Products /> },
      { path: "Articles", element: <Articles /> },
      { path: "Products", element: <Products /> },
      { path: "Products/:id", element: <Product /> },
    ],
  },
  {
    path: "dashboard",
    element: <Dashboard />,
    // loader: (e) => {
    //   if (store.getState().auth.userData?.role !== "admin") {
    //     window.location.href = "/";
    //   }
    //   return null;
    // },
    children: [
      { index: true, element: <UsersControl /> },
      { path: "users", element: <UsersControl /> },
      { path: "category", element: <Categories /> },
      { path: "products", element: <ProductsControl /> },
      { path: "PhotoSliders", element: <PhotoSliders /> },
      { path: "articles", element: <ArticlesControl /> },
      { path: "news", element: <NewsControl /> },
      { path: "AddSlider", element: <AddSlider /> },
      { path: "jobs", element: <JobsControl /> },
      { path: "jobRequests", element: <JobRequestsControl /> },
      { path: "previewRequests", element: <PreviewRequestsControl /> },
      { path: "aboutSarg", element: <AboutControl /> },
    ],
  },
  {
    path: "login",
    element: <Login />,
  },
]);
