import React, { useEffect, useRef } from "react";
import { Button, Carousel, Spin } from "antd";
import Aos from "aos";
import "aos/dist/aos.css"; // استيراد ملف الستايل الخاص ب AOS
import { useGetSlidesQuery } from "../../redux/Service/api";
import { language } from "../../language/Language";
const contentStyle = {
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#ffc107",
};

const AppTitles = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const {
    data: allSlidesData,
    isError: isSlidesError,
    isLoading: isSlidesLoading,
    error: slidesError,
  } = useGetSlidesQuery();
  const allTitles = allSlidesData?.data ?? null;

  return (
    <div
      className="col-12 col-lg-6 pt-lg-5 carousel_main d-flex flex-column justify-content-center"
      data-aos="fade-left"
      // style={{ marginTop: "-3rem" }}
    >
      <h1 className=" fw-bold new-heading-color carousel_heading">
        Eden-mare Construction
      </h1>
      <Carousel autoplay className="my-2 my-lg-0 pt-lg-5 ">
        {isSlidesLoading && <Spin />}
        {isSlidesError && <></>}
        {allTitles?.map((title) => (
          <div className="carousel_txet new-text-color" key={title.id}>
            <h3 className="">{title.title}</h3>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default AppTitles;
