import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  Modal,
  Space,
  Upload,
  message,
  Button,
  Input,
  DatePicker,
  Select,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import {
  // useAddOfferMutation,
  useAddProductMutation,
  // useGetAllBrandsQuery,
  useGetAllCategoriesQuery,
} from "../../redux/Service/api";
import TextArea from "antd/es/input/TextArea";

const AddProduct = ({ setOpenAddModal, openAddModal }) => {
  const [modalKey, setModalKey] = useState(0);
  // input number Edit
  const [nameEN, setNameEN] = useState("");
  const [nameAR, setNameAR] = useState("");
  const [price, setPrice] = useState("");
  // const [price$, setPrice$] = useState("");
  // const [priceAfterDiscount, setPriceAfterDiscount] = useState("");
  // const [priceAfterDiscount$, setPriceAfterDiscount$] = useState("");
  // const [manual, setManual] = useState("");
  // const [manualEN, setManualEN] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionEN, setDescriptionEN] = useState("");
  // const [available, setAvailable] = useState("active"); // nonActive
  // const [productionDate, setProductionDate] = useState("");
  const [categoryId, setCategoryId] = useState("");
  // const [brandId, setBrandId] = useState("");

  const [iconImage, setIconImage] = useState(null);
  const [subImages, setSubImages] = useState(null);

  const {
    data: allCategoriessData,
    isError: isAllCategoriesError,
    isLoading: isAllCategoriesLoading,
    error: allCategoriesError,
  } = useGetAllCategoriesQuery();

  const allCategories = allCategoriessData?.data ?? null;

  // const {
  //   data: allBrandsData,
  //   isError: isAllBrandsError,
  //   isLoading: isAllBrandsLoading,
  //   error: allBrandsError,
  // } = useGetAllBrandsQuery();

  console.log(allCategories);

  // const allBrands = allBrandsData?.data ?? null;

  const [addProduct, { isLoading, isError, error }] = useAddProductMutation();

  const handleIconImageChange = (fileList) => {
    if (fileList.length > 0) {
      setIconImage(fileList[0].originFileObj);
    } else {
      setIconImage(null);
    }
  };

  const handleSubImagesChange = (fileList) => {
    if (fileList.length > 0) {
      const firstFourFiles = fileList.slice(0, 4);
      const fileObjects = firstFourFiles.map((file) => file.originFileObj);
      setSubImages(fileObjects);
    } else {
      setSubImages(null);
    }
  };

  const handleAddProduct = async () => {
    if (!iconImage) {
      message.error("Please upload a main image");
      return;
    }
    if (!subImages) {
      message.error("Please upload a subImages");
      return;
    }
    if (!price) {
      message.error("Please insert the price");
      return;
    }
    // if (!price$) {
    //   message.error("Please insert the price in dollar");
    //   return;
    // }
    if (!nameEN) {
      message.error("Please add the english name");
      return;
    }
    if (!nameAR) {
      message.error("Please add the arabic name");
      return;
    }
    // if (!available) {
    //   message.error("Please select the availability of product");
    //   return;
    // }
    if (!description) {
      message.error("Please add the description");
      return;
    }
    if (!descriptionEN) {
      message.error("Please add the english description");
      return;
    }
    // if (!manual) {
    //   message.error("Please add the how to use");
    //   return;
    // }
    // if (!manualEN) {
    //   message.error("Please add the how to use in english");
    //   return;
    // }
    // if (!productionDate) {
    //   message.error("Please select the production Date");
    //   return;
    // }
    if (!categoryId) {
      message.error("Please select the category");
      return;
    }
    // if (!brandId) {
    //   message.error("Please select the brand");
    //   return;
    // }
    const formData = new FormData();
    formData.append("name", nameAR);
    formData.append("name_en", nameEN);
    // formData.append("available", available);
    formData.append("photo", iconImage);
    formData.append("details", description);
    formData.append("details_en", descriptionEN);
    // formData.append("manual", manual);
    // formData.append("manual_en", manualEN);
    // formData.append("production_date", productionDate);
    formData.append("category_id", categoryId);
    // formData.append("brand_id", brandId);
    formData.append("price", price);
    // formData.append("price_dollar", price$ ? price$ : 0);

    subImages?.forEach((file, index) => {
      formData.append(`photos[${index}]`, file);
    });

    // if (priceAfterDiscount && priceAfterDiscount > 0) {
    //   formData.append("discount", priceAfterDiscount);
    // }
    // if (priceAfterDiscount$ && priceAfterDiscount$ > 0) {
    //   formData.append(
    //     "discount_dollar",
    //     priceAfterDiscount$ ? priceAfterDiscount$ : 0
    //   );
    // }
    try {
      const res = await addProduct(formData);
      setIconImage(null);
      setSubImages(null);
      setNameAR("");
      setNameEN("");
      setPrice("");
      // setPrice$("");
      // setPriceAfterDiscount("");
      // setPriceAfterDiscount$("");
      // setAvailable("active");
      setCategoryId("");
      // setBrandId("");
      setDescription("");
      setDescriptionEN("");
      // setManual("");
      // setManualEN("");
      // setProductionDate("");

      setModalKey((prev) => prev + 1);
      res.data.message
        ? message.info(res.data.message)
        : message.success("Product added successfully");
      setOpenAddModal(false);
    } catch (error) {
      console.error("Error adding Product:", error);
      message.error("Error adding Product. Please try again.");
    }
  };

  return (
    <Modal
      key={modalKey}
      title="اضاف منتج"
      centered
      open={openAddModal}
      onCancel={() => setOpenAddModal(false)}
      width={1000}
      footer
      className="add-product-modal"
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size="large"
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
          size="large"
        >
          <Row className="g-2">
            <Col className="gap-3 d-flex flex-column">
              <Input
                placeholder="اسم المنتج"
                style={{ direction: "rtl", textAlign: "right" }}
                value={nameAR}
                onChange={(e) => setNameAR(e.target.value)}
              />
              {/* <TextArea
                placeholder="ما هو"
                style={{
                  direction: "rtl",
                  textAlign: "right",
                  height: "100px",
                }}
                value={manual}
                size="large"
                onChange={(e) => setManual(e.target.value)}
              /> */}
              <TextArea
                placeholder="تفاصيل المنتج"
                style={{
                  direction: "rtl",
                  textAlign: "right",
                  height: "100px",
                }}
                value={description}
                size="large"
                onChange={(e) => setDescription(e.target.value)}
              />
              <Input
                type="number"
                placeholder="السعر"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
              {/* <Input
                type="number"
                placeholder="السعر بالدولار"
                value={price$}
                onChange={(e) => setPrice$(e.target.value)}
              /> */}
            </Col>
            {/*  */}
            <Col className="gap-3 d-flex flex-column">
              <Input
                placeholder="name in english"
                value={nameEN}
                onChange={(e) => setNameEN(e.target.value)}
              />
              {/* <TextArea
                placeholder="what is this in english"
                value={manualEN}
                onChange={(e) => setManualEN(e.target.value)}
                size="large"
                style={{ height: "100px" }}
              /> */}
              <TextArea
                placeholder="Product details in english"
                value={descriptionEN}
                onChange={(e) => setDescriptionEN(e.target.value)}
                size="large"
                style={{ height: "100px" }}
              />
              {/* <Input
                type="number"
                placeholder="Price after discount"
                value={priceAfterDiscount}
                onChange={(e) => setPriceAfterDiscount(e.target.value)}
              /> */}
              {/* <Input
                type="number"
                placeholder="Price after discount in dollar"
                value={priceAfterDiscount$}
                onChange={(e) => setPriceAfterDiscount$(e.target.value)}
              /> */}
            </Col>
          </Row>
          {/*  */}
          <Row className="g-2">
            <Col className="">
              {/* <Select
                placeholder="Select Category"
                style={{
                  width: 200,
                }}
                onChange={(e) => setCategoryId(e)}
                options={allCategories?.map((category) => ({
                  value: category.id,
                  label: category.name,
                }))}
              /> */}
              <select
                className="select-type"
                placeholder="Select Category"
                onChange={(e) => {
                  console.log(e.target.value);
                  setCategoryId(e.target.value);
                }}
                // defaultValue=""
                value={categoryId}
              >
                <option value="" disabled>
                  Select category
                </option>
                {allCategories?.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </Col>
            {/* 
            <Col className="">
              <Select
                placeholder="Availability"
                style={{
                  width: 200,
                }}
                onChange={(e) => setAvailable(e)}
                defaultValue={available}
                options={[
                  {
                    value: "active",
                    label: "available",
                  },
                  {
                    value: "nonActive",
                    label: "not available",
                  },
                ]}
              />
            </Col> */}
            {/* <Col className="">
              <DatePicker
                placeholder="Production Date"
                onChange={(dateObj, dateString) => {
                  setProductionDate(dateString);
                }}
              />
            </Col> */}
          </Row>
          {/*  */}
          <Row className="g-2">
            <Col className="gap-1 d-flex flex-column">
              <Upload
                customRequest={({ file, onSuccess, onError }) => {
                  try {
                    onSuccess();
                  } catch (error) {
                    console.error("Error handling file:", error);
                  }
                }}
                onChange={({ fileList }) => {
                  handleIconImageChange(fileList);
                }}
                listType="picture"
                maxCount={1}
              >
                <Button
                  loading={false}
                  icon={<FontAwesomeIcon icon={faDownload} />}
                >
                  Upload Main Images (Max: 1)
                </Button>
              </Upload>
            </Col>
            <Col className="gap-1 d-flex flex-column ">
              <Upload
                customRequest={({ file, onSuccess, onError }) => {
                  try {
                    onSuccess();
                  } catch (error) {
                    console.error("Error handling file:", error);
                  }
                }}
                onChange={({ fileList }) => {
                  handleSubImagesChange(fileList);
                }}
                listType="picture"
                maxCount={4}
                multiple
              >
                <Button
                  loading={false}
                  icon={<FontAwesomeIcon icon={faDownload} />}
                >
                  Upload Sub Images (Max: 4)
                </Button>
              </Upload>
            </Col>
          </Row>

          <Row className="g-2">
            <Button
              type="primary"
              onClick={handleAddProduct}
              loading={isLoading}
            >
              Add Product
            </Button>
          </Row>
        </Space>
      </Space>
    </Modal>
  );
};

export default AddProduct;
