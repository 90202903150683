import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Data } from "../../constants";
import {
  faPlus,
  faStar,
  faStarHalf,
  faStarHalfStroke,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import "./Products.scss";
import { Button, Flex, message, Modal, Space, Upload } from "antd";
// import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import Loading from "../../components/utils/Loading";
import { Spin } from "antd";

import {
  useDeleteProductMutation,
  useGetAllCategoriesQuery,
  useGetAllProductsPerPageQuery,
  useGetAllProductsQuery,
  useMakeItemSpecialOrNotMutation,
} from "../../redux/Service/api";
import { useNavigate } from "react-router-dom";
import { ExclamationCircleFilled } from "@ant-design/icons";
import ServerError from "../../components/utils/ServerError";
import { language } from "../../language/Language";

// AOS.init({
//   once: false,
// });
const { confirm } = Modal;
const ProductsControl = () => {
  const catsref = useRef();
  const navigate = useNavigate();

  const [filterdProducts, setFilterdProducts] = useState(null);

  const {
    data: allProductsData,
    isError: isProdsError,
    isLoading: isProdsLoading,
    error: prodsError,
  } = useGetAllProductsQuery();
  const {
    data: allCategoriesData,
    isError: isCatError,
    isLoading: isCatLoading,
    error: catError,
  } = useGetAllCategoriesQuery();
  console.log(allProductsData);
  const allProducts = allProductsData?.data ?? null;
  console.log(allProducts);

  const pages = allProductsData?.data?.meta ?? null;
  console.log(pages);

  const allCategories = allCategoriesData?.data ?? null;
  console.log("allCategories", allCategories);

  const handleCatNameClick = (ele, catID) => {
    let lis = [...catsref.current.children];

    lis.forEach((li) => {
      li.classList?.remove("active");
    });

    ele.target.classList?.add("active");
    setFilterdProducts(() => {
      if (catID == 0) {
        return allProducts;
      } else {
        return allProducts?.filter(
          (product) => product.category?.id && product.category?.id == catID
        );
      }
    });
  };
  // useEffect(() => {
  //   setFilterdProducts(allProducts);
  // }, [allProducts]);

  const [
    switchSpecial,
    { isLoading: switchIsLoading, isError: switchIsError, error: switchError },
  ] = useMakeItemSpecialOrNotMutation();
  const [
    deleteProduct,
    { isLoading: delIsLoading, isError: delIsError, error: delError },
  ] = useDeleteProductMutation();

  const shownProducts = filterdProducts ?? allProducts ?? [];

  return (
    <section className="products page section_padding mt-0 pt-0 position-relative">
      <h2 className="main_heading">
        {language === "ar" ? "منتجاتنا" : "Our Products"}
      </h2>
      {isProdsLoading && <Loading />}
      {isProdsError && <ServerError />}
      <ul
        className="products_categories justify-content-center d-flex"
        ref={catsref}
      >
        <li
          className="active"
          data-filter="all"
          onClick={(e) => handleCatNameClick(e, 0)}
        >
          {language === "ar" ? "الكل" : "All"}
        </li>

        {allCategories?.map((category) => (
          <li
            data-filter={`.cat${category.id}`}
            key={category.id}
            onClick={(e) => handleCatNameClick(e, category.id)}
          >
            {category.name}
          </li>
        ))}
      </ul>
      <Container className="my-5">
        <Row>
          {shownProducts?.map((prod) => (
            <Col
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={prod.id}
              // className={`mb-4 ${prod.category.id} mix`}
            >
              <div
                className="product overflow-hidden"
                onClick={() => navigate(`/Products/${prod.id}`)}
              >
                <div className="image_hold">
                  <img src={prod.photo} alt="product" className="mw-100" />
                </div>
                <div className="product_info">
                  <p className="image_desc mb-2">
                    <span>{prod.name}</span>
                  </p>

                  <div className="price">
                    <span
                      className={
                        prod.discount !== 0 ? "oldprice" : "currentPrice"
                      }
                    >
                      EGP {prod.price}
                    </span>
                    {prod.discount !== 0 && (
                      <span className="currentPrice">EGP {prod.discount}</span>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ProductsControl;
