// import { faHeart, faStar } from "@fortawesome/free-solid-svg-icons";
// import { faHeart as regfaHeart } from "@fortawesome/free-regular-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import "./Product.css";

import {
  // useAddOrRemoveFavouriteProductMutation,
  // useAddToCartMutation,
  // useGetFavouritesProductsQuery,
  useGetOneProductQuery,
} from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import { Image, message, Modal, Spin } from "antd";
import { language } from "../../language/Language";
import ServerError from "../../components/utils/ServerError";

const Product = () => {
  const [productCount, setProductCount] = useState(1);
  const { isAuthenticated } = useSelector((st) => st.auth);
  const { id } = useParams();
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoSrc, setVideoSrc] = useState(null);

  const handleVideoClick = (src) => {
    setVideoSrc(src);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setVideoSrc(null);
  };
  const info = (message) => {
    messageApi.open({
      type: `${message ? "danger" : "success"}`,
      content: `${message ? message : "item is added"}`,

      style: {
        marginTop: "10vh",
      },
    });
  };

  const {
    data: productData,
    isError: isProdError,
    isLoading: isProdLoading,
    error: prodError,
  } = useGetOneProductQuery(id);

  console.log(productData);

  // const [
  //   addToCart,
  //   {
  //     data: addData,
  //     isLoading: addIsLoading,
  //     isError: addIsError,
  //     error: addError,
  //     isSuccess: addIsSuccess,
  //   },
  // ] = useAddToCartMutation();

  const product = productData?.data ?? null;
  // const [
  //   addOrDeleteProduct,
  //   {
  //     isLoading: addOrDelIsLoading,
  //     isError: addOrDelIsError,
  //     error: addOrDelError,
  //   },
  // ] = useAddOrRemoveFavouriteProductMutation();
  // const {
  //   data: favItemsData,
  //   isError: isFavError,
  //   isLoading: isFavLoading,
  //   error: favError,
  // } = useGetFavouritesProductsQuery();

  // const favItems = favItemsData?.data ?? null;

  // let handlefav = (ele) => {
  //   addOrDeleteProduct({ item_id: ele.id });
  // };

  // const handleAddToCart = async () => {
  //   if (!isAuthenticated) {
  //     navigate("/login");
  //   }
  //   try {
  //     const res = await addToCart({
  //       item_id: product?.id,
  //       count: productCount,
  //     });
  //     info(res.data.message);

  //     setProductCount(1);
  //   } catch (error) {
  //     console.error("Error adding item to cart:", error);
  //   }
  // };
  return (
    <section
      className="Product section_padding"
      style={{ position: "relative" }}
    >
      {contextHolder}
      <Container>
        {isProdLoading && <Loading />}
        {isProdError && <ServerError />}
        {
          <Row>
            <Col xs={12} md={6} className="flex_center flex-wrap">
              <div className="Product_image_holder d-flex flex-column gap-2">
                <div className="Product_image-holder">
                  <Image
                    src={product?.photo}
                    alt="product"
                    style={{ minWidth: "25rem", maxHeight: "30rem" }}
                  />
                </div>
                {product?.photos?.length > 0 && (
                  <div className="d-flex gap-1">
                    {product?.photos?.map((photo) =>
                      photo.type == "photo" ? (
                        <Image
                          key={photo.path}
                          src={photo.path}
                          alt="product"
                          className="mx-1 small-img"
                          style={{ width: "5rem", height: "5rem" }}
                        />
                      ) : (
                        <></>
                      )
                    )}
                    {product?.photos?.map((photo) =>
                      photo.type == "video" ? (
                        <video
                          key={photo.path}
                          src={photo.path}
                          // controls
                          alt="product"
                          className="mx-1 small-img"
                          style={{
                            width: "5rem",
                            height: "5rem",
                            cursor: "pointer",
                          }}
                          onClick={() => handleVideoClick(photo.path)}
                        ></video>
                      ) : (
                        <></>
                      )
                    )}
                  </div>
                )}
              </div>
            </Col>
            <Modal
              open={isModalOpen}
              onCancel={closeModal}
              footer={null}
              width="90%"
              style={{ top: 30 }}
            >
              {videoSrc && (
                <video
                  src={videoSrc}
                  controls
                  style={{
                    width: "100%",
                    marginTop: "20px",
                    maxHeight: "80vh",
                  }}
                ></video>
              )}
            </Modal>
            <Col xs={12} md={6} className="flex_center mt-sm-2">
              <div className="product_info flex_center flex-column gap-2">
                <p className="cat">{product?.category.name}</p>
                <p className="name">{product?.name}</p>

                <div className="price">
                  <span>
                    {product?.price}
                    {" EGP "}
                  </span>
                </div>
                <p className="text text-center fw-bold">
                  {product?.details.split("#").map((line, index) => (
                    <span key={index}>
                      {line}
                      <br />
                    </span>
                  ))}
                </p>

                <Link className="backtopro" to="/Products">
                  {language === "ar"
                    ? "العودة إلى المنتجات" // Correcting the phrase slightly for better Arabic grammar
                    : "Back to Products"}
                </Link>
              </div>
            </Col>
          </Row>
        }
      </Container>
    </section>
  );
};

export default Product;
