import { faPenToSquare, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DeleteFilled } from "@ant-design/icons";
import { ExclamationCircleFilled } from "@ant-design/icons";

import { Button, Card, Flex, message, Modal, Row } from "antd";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import {
  useDeleteJobMutation,
  useGetAllJobsQuery,
} from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import EditJob from "./EditJob";
import AddJob from "./AddJob";
import { language } from "../../language/Language";

const { Meta } = Card;
const { confirm } = Modal;

function JobsControl() {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [rerenderEditKey, setRerenderEditKey] = useState(0);
  const [tipIdForEdit, setTipIdForEdit] = useState(null);

  const [modal, contextHolder] = Modal.useModal();

  const {
    data: allJobsData,
    isError: isAllJobsError,
    isLoading: isAllJobsLoading,
    error: allJobsError,
  } = useGetAllJobsQuery();

  const allJobs = allJobsData?.data ?? null;

  const [
    deleteJob,
    { isLoading: delIsLoading, isError: delIsError, error: delError },
  ] = useDeleteJobMutation();

  const handleDeleteJob = async (id) => {
    confirm({
      title: "Do you really want to delete this Job?",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button, this Job will be removed ",
      async onOk() {
        try {
          const res = await deleteJob(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("Job is deleted successfully");
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {},
    });
  };

  return (
    <section
      className=" section_padding mt-0 pt-0 position-relative"
      dir={language === "ar" ? "rtl" : "ltr"}
    >
      <h2 className="main_heading"> Jobs </h2>
      {isAllJobsLoading && <Loading />}
      {(isAllJobsError || delIsError) && (
        <p className="text-danger">Something went error</p>
      )}
      {contextHolder}
      <Flex gap="small" wrap="nowrap" className="justify-content-center mb-5">
        <Button type="primary" onClick={() => setOpenAddModal(true)}>
          <FontAwesomeIcon icon={faPlus} /> Add a Job{" "}
        </Button>
      </Flex>
      <Container className="my-5">
        <Row className="gap-5 justify-content-center">
          {allJobs?.map((job) => (
            <Card
              key={job.id}
              hoverable
              className="cat col-12 col-md-5 col-xl-3 border-2"
              cover={
                <p
                  className="p-3 line-coverage fs-6 pb-0 mb-0"
                  style={{ textAlign: "start" }}
                >
                  {job.title}
                </p>
              }
              actions={[
                <DeleteFilled
                  key="setting"
                  style={{ fontSize: "20px" }}
                  onClick={() => handleDeleteJob(job.id)}
                />,
                <FontAwesomeIcon
                  style={{ fontSize: "20px" }}
                  icon={faPenToSquare}
                  onClick={() => {
                    setTipIdForEdit(job.id);
                    setOpenEditModal(true);
                    setRerenderEditKey((prev) => prev + 1);
                  }}
                />,
              ]}
            >
              <Meta title={job.description} />
            </Card>
          ))}
        </Row>
        <EditJob
          setOpenEditModal={setOpenEditModal}
          openEditModal={openEditModal}
          tipIdForEdit={tipIdForEdit}
          rerenderEditKey={rerenderEditKey}
        />

        <AddJob setOpenAddModal={setOpenAddModal} openAddModal={openAddModal} />
      </Container>
    </section>
  );
}

export default JobsControl;
