import { useParams } from "react-router-dom";
// import bg from "../../assets/Products1.jpg";
import Loading from "../../components/utils/Loading";
import { useGetOneNewsQuery } from "../../redux/Service/api";

import Linkify from "react-linkify";

const NewsDetails = () => {
  const { newsId } = useParams();
  const {
    data: newsData,
    isError: isNewsError,
    isLoading: isNewsLoading,
    error: newsError,
  } = useGetOneNewsQuery(newsId);

  const newsDetails = newsData?.data ?? null;

  const CustomLink = (href, text, key) => (
    <a href={href} target="_blank" rel="noopener noreferrer" key={key}>
      {text}
    </a>
  );

  return (
    <section
      className="position-relative pt-5 news mb-1"
      style={{ minHeight: "50vh" }}
    >
      {isNewsLoading && <Loading />}

      {newsDetails && (
        <div className="two-thirds-image-left-one-third-text-right container pb-5">
          <div className="image">
            <img
              src={newsDetails.photo}
              style={{
                maxHeight: "25rem",
                // objectFit: "contain",
                // minWidth: "100%",
              }}
              alt=""
              className=" rounded-3"
            />
          </div>
          <div className="text">
            <h3 className="title new-heading-color">{newsDetails.title}</h3>
            <span className="lead">{newsDetails.author}</span>
            {/* <p className="content new-text-color">{newsDetails.content}</p> */}
            <p className="content new-text-color">
              <Linkify componentDecorator={CustomLink}>
                {newsDetails.content}
              </Linkify>{" "}
            </p>
          </div>
        </div>
      )}
      {isNewsError && <></>}
    </section>
  );
};
export default NewsDetails;
