import { Image } from "react-bootstrap";
import "./Jobs.css";
// import logo from "../../assets/logo.jpg";
import logo from "../../assets/logo.png";

import { useGetAllJobsQuery } from "../../redux/Service/api";
import { Button } from "antd";
import ApplyJob from "./ApplyJob";
import { useState } from "react";
import { language } from "../../language/Language";

function Jobs() {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [jobIdForApply, setJobIdForApply] = useState(null);

  const {
    data: allJobsData,
    isError: isAllJobsError,
    isLoading: isAllJobsLoading,
    error: allJobsError,
  } = useGetAllJobsQuery();

  const allJobs = allJobsData?.data ?? null;
  return (
    <div className="container my-3 py-4 py-lg-5 my-lg-5">
      {/* <!--end row--> */}
      <h1 className="heading gap-3 d-flex align-items-center justify-content-center">
        {language === "ar"
          ? "الوظائف المتاحة لدي الشركه"
          : "Jobs available in eden mare"}
        <Image className="col-2 rounded-5 heading" src={logo} />
      </h1>
      <div className="row">
        {allJobs?.map((job) => (
          <div className="col-lg-4 col-md-6 col-12 my-3 pt-2" key={job.id}>
            <div className="card border-0 new-light-bg-color rounded shadow">
              <div className="card-body p-4">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="new-heading-color">{job.title}</h5>
                  <span className="badge rounded-pill new-heading-color new-bg-color float-md-end mb-3 mb-sm-0">
                    {language === "ar" ? "وقت كامل" : "Full time"}
                  </span>
                </div>
                <p className="new-text-color">{job.description}</p>
                <div className="mt-3">
                  <button
                    // type="primary"
                    // ghost
                    // color="red"
                    className="new-bg-orange-color new-text-color rounded-2 border-0 px-2"
                    onClick={() => {
                      setJobIdForApply(job.id);
                      setOpenAddModal(true);
                    }}
                  >
                    {language === "ar" ? "تقدم الآن" : "Apply now"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* <!--end row--> */}
      <ApplyJob
        setOpenAddModal={setOpenAddModal}
        openAddModal={openAddModal}
        jobIdForApply={jobIdForApply}
      />
    </div>
  );
}

export default Jobs;
