import { Spin } from "antd";
import React from "react";
import { Container } from "react-bootstrap";
import profile_admin from "../../assets/restaurant.jpg";
import profile_admin2 from "../../assets/heroImgjpg.jpg";
import "./Profile.css";
import { language } from "../../language/Language";
import { useSelector } from "react-redux";
function Profile({ allAbouts, isAllAboutsError, isAllAboutsLoading }) {
  const { mode } = useSelector((st) => st.DarkMode);

  return (
    <div className="Profile_rtl d-flex align-items-center flex-wrap row mb-5 h-50 mt-5 justify-content-between">
      <div className=" col-lg-7">
        {allAbouts?.map((about, idx) => (
          <img
            key={idx}
            src={about.photo}
            className="rounded-3 img-fluid"
            alt=""
            style={{
              maxHeight: "450px",
              objectFit: "cover",
              objectPosition: "top",
              width: "80%",
            }}
          />
        ))}
        {isAllAboutsError && (
          <img
            src={mode ? profile_admin : profile_admin2}
            className="rounded-3 w-100 img-fluid"
            alt=""
            style={{ height: "400px", objectFit: "contain" }}
          />
        )}
      </div>
      {isAllAboutsLoading && <Spin />}
      {allAbouts?.map((about) => (
        <div className="col-12 col-lg-4" key={about.id}>
          <div className="language_about">
            <p className="new-heading-color mb-3 fs-4 fw-bold">
              {about.manager_name}
            </p>
            <h3
              className="new-text-color fw-bold"
              style={{ fontFamily: "Pacifico, cursive" }}
            >
              {about.manager_position}
            </h3>
            <p className="new-text-color">{about.manager_information}</p>
          </div>
        </div>
      ))}
      {isAllAboutsError && <></>}
    </div>
  );
}
export default Profile;
