import { Button } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../redux/Service/api";
import { reduxLogin } from "../../redux/Slices/authSlice";
import "./Login.css";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState("");

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [loginUser, { isLoading, isError, error }] = useLoginMutation();

  const handleLoginClick = async (e) => {
    e.preventDefault();

    try {
      const result = await loginUser({
        email: loginData.email,
        password: loginData.password,
      });

      const token = result?.data?.data?.token ?? null;
      if (token) {
        dispatch(reduxLogin());
        localStorage.setItem("edenMareUserToken", token);

        navigate("/dashboard");
      } else {
        setErrorMessage(
          result.error?.message ||
            result?.data?.message ||
            "something went wrong"
        );
        console.log("err");
      }
    } catch (error) {
      console.error("Registration failed:", error);
    }
  };

  return (
    <section className="login section_padding flex_center">
      <div className="login_cont">
        <h2 className="new-heading-color">Login</h2>
        <p className="new-text-color">Enter Login details to get access</p>
        {errorMessage && <p className="text-danger">{errorMessage}</p>}
        <form action="">
          <label className="new-text-color" htmlFor="email">
            Username Or Email Address
          </label>
          <input
            type="email"
            name="email"
            id="email"
            className=" text-black"
            placeholder=" Email Address"
            value={loginData.email}
            onChange={(e) =>
              setLoginData((prev) => {
                return { ...prev, [e.target.name]: e.target.value };
              })
            }
          />
          <label className="new-text-color" htmlFor="password">
            Username Or Email Address
          </label>
          <input
            className="text-black"
            type="password"
            name="password"
            id="password"
            placeholder="Enter Password"
            value={loginData.password}
            onChange={(e) =>
              setLoginData((prev) => {
                return { ...prev, [e.target.name]: e.target.value };
              })
            }
          />

          <div className="logEnd d-flex w-100 justify-align-content">
            <Button
              // type="primary"
              ghost
              className="px-4 pt-1 pb-1 mt-4 new-bg-orange-color text-white"
              onClick={handleLoginClick}
              loading={isLoading}
            >
              Login
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Login;
