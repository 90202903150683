// import { Col, Container, Row } from "react-bootstrap";
// import { Image } from "antd";
import { useParams } from "react-router-dom";
// import bg from "../../assets/Products1.jpg";
import Loading from "../../components/utils/Loading";
import { useGetOneArticleQuery } from "../../redux/Service/api";
import Linkify from "react-linkify";
import "./Article.css";
const ArticleDetails = () => {
  const { articleId } = useParams();
  const {
    data: articleData,
    isError: isArticleError,
    isLoading: isArticleLoading,
    error: articleError,
  } = useGetOneArticleQuery(articleId);

  const articleDetails = articleData?.data ?? null;

  const CustomLink = (href, text, key) => (
    <a href={href} target="_blank" rel="noopener noreferrer" key={key}>
      {text}
    </a>
  );

  return (
    <section
      className="position-relative pt-5 article mb-1 new-bg-color"
      style={{ minHeight: "50vh" }}
    >
      {isArticleLoading && <Loading />}
      {articleDetails && (
        <div className="two-thirds-image-left-one-third-text-right container pb-5">
          <div className="image">
            <img
              src={articleDetails.photo}
              style={{ maxHeight: "25rem" }}
              className=" rounded-3"
              alt=""
            />
          </div>
          <div className="text">
            <h3 className="title new-heading-color">{articleDetails.title}</h3>
            <span className="lead">{articleDetails.author}</span>
            {/* <p className="content new-text-color">{articleDetails.content}</p> */}
            <p className="content new-text-color">
              <Linkify componentDecorator={CustomLink}>
                {articleDetails.content}
              </Linkify>{" "}
            </p>
          </div>
        </div>
      )}
      {isArticleError && <></>}
    </section>
  );
};

export default ArticleDetails;
