import { CloseCircleOutlined } from "@ant-design/icons";
import { language } from "../../language/Language";

const ServerError = () => {
  return (
    <div
      style={{
        textAlign: "center",
        margin: "1rem 0",
        fontWeight: "500",
        fontSize: "1.2rem",
        color: "#df5090",
      }}
    >
      <CloseCircleOutlined />

      {language === "ar" ? (
        <p>هناك خطأ ما وسوف نعمل علي حله قريبا..</p>
      ) : (
        <p>Oops, something went wrong; we'll fix it soon..</p>
      )}
    </div>
  );
};

export default ServerError;
