import React, { useEffect } from "react";
import "./AboutUs.css";
import { Image } from "react-bootstrap";

// import logo from "../../assets/logo.jpg";
// import logo from "../../assets/headingLightLogo.png";
// import logo from "../../assets/headingDarkLogo.png";
import logo from "../../assets/logo.png";
import Aos from "aos";
import "aos/dist/aos.css"; // استيراد ملف الستايل الخاص ب AOS
import Profile from "../Profile/Profile";
import { useGetAllAboutsQuery } from "../../redux/Service/api";
import { Spin } from "antd";
import { language } from "../../language/Language";

function AboutUs() {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  const {
    data: allAboutsData,
    isError: isAllAboutsError,
    isLoading: isAllAboutsLoading,
    error: allAboutsError,
  } = useGetAllAboutsQuery();

  const allAbouts = allAboutsData?.data ?? null;

  return (
    <div
      className="aboutUs mt-5"
      // data-aos="fade-up"
      style={{ direction: "rtl" }}
    >
      {/* <h1 className="heading gap-3 d-flex align-items-center justify-content-center mb-4 mb-lg-0">
        <Image className="col-2 rounded-5 heading" src={logo} />
        {language === "en" ? "About us" : "نبذة عن الشركة"}
      </h1> */}

      <div
        className="d-flex flex-wrap row mb-5"
        data-aos="fade-up"
        dir={language === "ar" ? "ltr" : "rtl"}
      >
        <div className="col-12 col-lg-6" data-aos="fade-left">
          <div className="text_end fw-bold mt-lg-5 pt-lg-5">
            {isAllAboutsLoading && <Spin />}
            {allAbouts?.map((about) => (
              <p
                key={about.id}
                className="fs-5 text-center new-text-color"
                style={{
                  lineHeight: 1.5,
                  marginTop: "-1rem",
                  fontWeight: "400",
                }}
              >
                {about.about_us}
              </p>
            ))}
            {isAllAboutsError && <></>}
          </div>
        </div>
        {allAbouts?.map((about) => (
          <div className="col-12 col-lg-6 videoAbout rounded-1">
            <video
              src={about.video}
              controls
              width="100%"
              height="auto"
              className="rounded-1"
              type="video/mp4"
              autoPlay
              loop
              muted
            ></video>
          </div>
        ))}
        {/* {isAllAboutsError && (
          <div className="col-12 col-lg-6 videoAbout rounded-1">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m21!1m12!1m3!1d1221.2795626470481!2d31.164733967656314!3d30.028232848425525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m6!3e6!4m0!4m3!3m2!1d30.028423325251858!2d31.16464293047656!5e0!3m2!1sen!2seg!4v1725808812024!5m2!1sen!2seg"
              width="100%"
              height="350"
              style={{
                border: "none",
                overflow: "hidden",
                borderRadius: "1rem",
              }}
              scrolling="no"
              frameBorder="0"
              title="about us video"
              allowFullScreen={true}
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
          </div>
        )} */}
      </div>
      <Profile
        allAbouts={allAbouts}
        isAllAboutsError={isAllAboutsError}
        isAllAboutsLoading={isAllAboutsLoading}
      />
    </div>
  );
}

export default AboutUs;
