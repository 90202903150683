import { Button, Layout, Menu } from "antd";
import React, { useLayoutEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "./style.css";
// import { images } from "../constants";
// import logo from "../assets/logo.jpg";
import logo from "../assets/logoBlack.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNewspaper } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowsToEye,
  faCartPlus,
  faPercent,
  faShop,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { reduxLogout } from "../redux/Slices/authSlice";

const { Content, Footer, Sider, Header } = Layout;

document.body.dir = "ltr";

const sidebarTabs = [
  {
    key: "users",
    label: "Users",
    icon: <FontAwesomeIcon icon={faUserGroup} />,
  },
  {
    key: "Products",
    label: "products",
    icon: <FontAwesomeIcon icon={faShop} />,
  },
  {
    key: "category",
    label: "Categories",
    icon: <FontAwesomeIcon icon={faShop} />,
  },
  {
    key: "PhotoSliders",
    label: "Slider Photos",
    icon: <FontAwesomeIcon icon={faShop} />,
  },
  {
    key: "articles",
    label: "Articles",
    icon: <FontAwesomeIcon icon={faNewspaper} />,
  },
  {
    key: "news",
    label: "News",
    icon: <FontAwesomeIcon icon={faUserGroup} />,
  },
  {
    key: "aboutSarg",
    label: "About us",
    icon: <FontAwesomeIcon icon={faShop} />,
  },
  {
    key: "AddSlider",
    label: "slider title",
    icon: <FontAwesomeIcon icon={faArrowsToEye} />,
  },
  {
    key: "jobs",
    label: "Jobs",
    icon: <FontAwesomeIcon icon={faUserGroup} />,
  },
  {
    key: "jobRequests",
    label: "Job Requests",
    icon: <FontAwesomeIcon icon={faUserGroup} />,
  },
  {
    key: "previewRequests",
    label: "Preview Requests",
    icon: <FontAwesomeIcon icon={faUserGroup} />,
  },
];

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((st) => st.auth);
  const [siderCollapsed, setSiderCollapsed] = React.useState(false);

  useLayoutEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  const handleTabClick = (event) => {
    const clickedTabKey = event.key;
    navigate(`/dashboard/${clickedTabKey}`);
  };
  const handleLogout = () => {
    localStorage.removeItem("edenMareUserToken");
    dispatch(reduxLogout());
  };
  return (
    <Layout className="dashboard" hasSider>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {}}
        onCollapse={(collapsed, type) => {
          // console.log("collapsed", collapsed, "type", type);
          setSiderCollapsed(collapsed);
          if (type === "clickTrigger" && !collapsed)
            setSiderCollapsed(!collapsed);
        }}
        style={{
          position: "fixed",
          height: "100vh",
          top: 0,
          bottom: 0,
          zIndex: 1000,
        }}
      >
        <div
          className="logo mb-2 bg-dark-subtle"
          style={{ color: "white", cursor: "pointer" }}
          onClick={() => navigate("/")}
        >
          <img src={logo} alt="logo" style={{ width: "100%" }} />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["users"]}
          items={sidebarTabs}
          onClick={handleTabClick}
        />
        <div
          style={{
            position: "absolute",
            bottom: "1rem",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <Button
            type="primary"
            style={{ width: "80%" }}
            onClick={handleLogout}
          >
            logout
          </Button>
        </div>
      </Sider>
      <Layout
        style={
          {
            // margin: "24px 0 0",
          }
        }
      >
        {/* <Header
          style={{
            padding: 0,
            background: "red",
          }}
        /> */}
        <Content
          style={{ margin: !siderCollapsed ? "0px 0 0 12rem" : "initial" }}
        >
          <div className="site-layout-background content">
            <Outlet />
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          Eden mare
        </Footer>
      </Layout>
    </Layout>
  );
};
export default Dashboard;
